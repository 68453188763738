import React from "react";
export const SC_Roles = {
  Admin: "Administrator",
  ClientAdmin: "Client Administrator",
  Client: "Client",
  Service: "Service",
  QuickStart: "QuickStartTrial",
};
export const ConnectorValues = [
  "SAP",
  "Salesforce",
  "Dynamics",
  "Oracle",
  "API",
  "Zuora",
];
export const SC_Constants = {
  sessionExpiryMsg: "Your session expired. Please log in again.",
  tokenExpiryMsg: "Token has expired, Please log in again", //msg to show when logout due to tokenexpiry.
  TrialClientServId: "QuickStartTrialClient",
  emailValidationPattern:
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  PageHeadings: {
    Home: "Dashboard",
    Client: "Client Management",
    Users: "User Accounts",
    Transactions: "Transactions",
    CallOuts: "Callouts",

    // this heading is being displayed on screen side bar
    Swagger: "API Reference",
    QuickStart: "QuickStart Trial",
    Documentation: "Documentation",
    Integrations: "Integrations",
  },
  Dashboards: {
    clientsDefaultFilterOptions: [{ Name: "Top 5 Clients", GUID: "top5" }],
    datesDefaultFilterOptions: [
      { id: "0", name: "Today" },
      { id: "0-6", name: "Last Week" },
      { id: "0-30", name: "Last Month" },
      { id: "0-6m", name: "Last 6 months" },
      { id: "0-12m", name: "Last 12 months" },
    ],
  },
  UserAccounts: {
    roles: [
      { ID: SC_Roles.Admin, Name: "Administrator" },
      { ID: SC_Roles.ClientAdmin, Name: "Customer Admin" },
      { ID: SC_Roles.Client, Name: "Client" },
      { ID: SC_Roles.Service, Name: "Service" },
    ],
    userAccount: {
      username: "",
      name: "",
      email: "",
      phone: "",
      role: "Administrator",
      clientId: "",
    },
    updateUserAccount: {
      Id: "",
      Username: "",
      Name: "",
      Email: "",
      Phone: "",
      Role: "Administrator",
      ClientId: "",
    },
    deleteUserAccount: {
      username: "",
      role: "",
    },
    phonePattern: /^[0-9]{10,13}$/,
    editPhonePattern: /^\+[0-9]{11,15}$/,
    usernamePattern: /^\S*$/,
    phoneEditorOptions: {
      mask: "+1X000000000",
      maskRules: {
        X: /[02-9]/,
      },
      useMaskedValue: true,
      maskInvalidMessage: "The phone must have a correct USA phone format",
    },
  },
  Transactions: {
    filterTypes: {
      clients: "clients",
      dates: "dates",
      requestId: "requestId",
      reqResetCall: "request reset call",
      lastkey: "lastExclusiveKey",
    },
    datesFilterOptions: [
      { id: "0", name: "Today" },
      { id: "0-14", name: "Last 15 days" },
      { id: "0-30", name: "Last month" },
      { id: "0-3m", name: "Last 3 months" },
    ],
  },


  QuickTrial: {
    myEms: "My_EMS",
    myEmsBtn: "My EMS",
    testEms: "Test_EMS",
    testEmsBtn: "Test EMS",
  },
};
export const SC_Routes = {
  Dashboards: "/home",
  Login: "/login",
  FreeTrialSignup: "/free-trial-signup",
  Clients: "/clients",
  UserAcc: "/useraccounts",
  Transactions: "/transactions",
  Callouts: "/callouts",

  // this is the router in url of page.
  Swagger: "/swagger",
  QuickStart: "/quickstarttrial",
  Documentation:
    "https://docs.sentinel.thalesgroup.com/softwareandservices/connect/main/home.htm",
  Integrations: "/integrations",
};
export const SC_Api = {
  quickTrial_ViewSourceCode: "viewsourcecode",
  quickTrial: "quick-start-trial",
  clients: "clients",
  verifyEmsDetails: "clients/verifyemsdetails",
  VerifyZuoraDetails: "clients/verifyZuoradetails",
  trialSignup: "quicktrialsignup",
  dashboards: "dashboards",
  transactions: "transactions",
  transactionLogs: "transactions/logs",
  users: "users",
  callouts: "callouts",
  verifyCalloutDetails: "callouts/verifyCallout",
  getcustomAttributes: "customAttributes"
};
export const isValidPageWithoutAuthorization = {
  Login: "/login",
  FreeTrialSignup: "/free-trial-signup",
}

export const invalidPage = {
  dotenv: "/.env",
  env: "/env"
}

// QuickTrial
export const TrialSteps = [
  {
    index: 1,
    heading: "Set EMS",
    content: [
      "Entitlement transactions will be automated in the selected Sentinel EMS environment. ",
      // "“Test EMS” is used by default",
      // " however you may choose “My EMS” to use your own non-production EMS environment. ",
      "Select “My EMS” to use your own non-production EMS (do not use production EMS). ",
      "“Test EMS” uses a generic sandbox. ",
      "Additional EMS configuration is ",
      <span key="quick-trial-step-1-not-key" className="font-weight-bold">
        not
      </span>,
      " needed, this trial uses pre-set data.",
    ],
    postContent: "",
    buttons: ["Test EMS", "My EMS"],
    hideShowCode: true,
  },
  {
    index: 2,
    heading: "Create New Subscription",
    content:
      "On success a new subscription entitlement, with pre-set data, is created in EMS.",
    postContent: "",
    buttons: ["Create New Subscription"],
  },
  {
    index: 3,
    heading: "Renew Subscription - 1 year extension",
    content: "On success, the entitlement end date changes by 1 year in EMS.",
    postContent: "",
    buttons: ["Renew Subscription - 1 year extension"],
  },
  {
    index: 4,
    heading: "Cancel Subscription",
    content:
      "On success, the entitlement status changes to “Closed” in EMS making the entitlement permanently unusable.",
    postContent: "",
    buttons: ["Cancel Subscription"],
  },
];
export const TrialStepSourceStatus = {
  1: {
    show: false,
    req: "",
    res: "",
    reqCopied: false,
  },
  2: {
    show: false,
    req: "",
    res: "",
    reqCopied: false,
  },
  3: {
    show: false,
    req: "",
    res: "",
    reqCopied: false,
  },
  4: {
    show: false,
    req: "",
    res: "",
    reqCopied: false,
  },
};
// Transactions
export const responseFolderName = "EntitlementResponse";
// Clients
export const versions = [
  { ID: "5.1", Name: "5.1" },
  { ID: "5.0", Name: "5.0" },
  { ID: "4.4", Name: "4.4" },
  { ID: "4.2", Name: "4.2" },
  { ID: "4.0", Name: "4.0" },
];
export const lookupParams = [
  {
    scope: "Entitlement",
    entities: [
      {
        name: { key: "entitlement", value: "Entitlement" },
        function: "search",
        elements: [
          { key: "EID", value: "EID" },
          { key: "refId1", value: "ref Id1" },
          { key: "refId2", value: "ref Id2" },
          { key: "externalId", value: "external Id" }
        ],
        operator: "any",
      },
      {
        name: { key: "customer", value: "Customer" },
        function: "search",
        elements: [
          { key: "customerIdentifier", value: "customer Identifier" },
          { key: "externalId", value: "external Id" },
          { key: "refId", value: "ref Id" },
          { key: "crmId", value: "crm Id" }
        ],
        operator: "any",
      },
      {
        name: { key: "product", value: "Product" },
        function: "search",
        elements: [
          { key: "productName+version", value: "product Name+version" },
          { key: "refId1", value: "ref Id1" },
          { key: "refId2", value: "ref Id2" },
          { key: "externalId", value: "external Id" },
          { key: "Skip error, if product not found", value: "Skip error, if product not found" }
        ],
        operator: "any",
      },
      {
        name: { key: "channelPartner", value: "Channel Partner" },
        function: "search",
        elements: [
          { key: "partnerIdentifier", value: "partner Identifier" },
          { key: "refId1", value: "ref Id1" },
          { key: "refId2", value: "ref Id2" },
          { key: "externalId", value: "external Id" }
        ],
        operator: "any",
      },
      {
        name: { key: "customerContact", value: "Users" },
        function: "search",
        elements: [
          { key: "contactEmail", value: "contact Email" },
          { key: "refId1", value: "ref Id1" },
          { key: "refId2", value: "ref Id2" },
          { key: "externalId", value: "external Id" }
        ],
        operator: "any",
      },
      {
        name: { key: "sentinelKeyUpdate", value: "Sentinel Key (Applicable to LDK Enforcement)" },
        function: "search",
        elements: [
          { key: "oldEntitlementId", value: "Entitlement Id" },
          { key: "oldKeyId", value: "Key Id" },
          { key: "oldRefId1", value: "Ref Id1" },
          { key: "oldRefId2", value: "Ref Id2" }
        ],
        operator: "any",
      },
    ],
  },
];
export const objectRules = [
  {
    scope: { key: "Entitlement", value: "Entitlement" },
    rules: [
      { key: "ClientGeneratesEID", value: "Client Generates EID" },
      { key: "CreateEntitlementPerLine", value: "Create Entitlement Per Line" },
      { key: "RenewCloudLeaseOnEntitlementUpdate", value: "Renew Cloud Lease On Entitlement Update" },
      { key: "EnableFeatureSelectionInResponse", value: "Enable Feature Selection In Response" },
      { key: "QuantityOverride", value: "Quantity Override" },
      { key: "SentinelKeyUpdate", value: "Sentinel Key Updates (Applicable to LDK Enforcement)" }
    ]
  },
  {
    scope: { key: "customer", value: "Customer" },
    rules: [
      { key: "CreateNewIfNotFound", value: "Create New If Not Found" },
      { key: "ErrorIfNotFound", value: "Error If Not Found" },
      { key: "UpdateExistingIfFound", value: "Update Existing If Found" },
      { key: "ErrorIfFoundAndObjectChanged", value: "Error If Found And Object Changed" }
    ]
  },
  {
    scope: { key: "customerContact", value: "Users" },
    rules: [
      { key: "CreateNewIfNotFound", value: "Create New If Not Found" },
      { key: "ErrorIfNotFound", value: "Error If Not Found" },
      { key: "UpdateExistingIfFound", value: "Update Existing If Found" },
      { key: "ErrorIfFoundAndObjectChanged", value: "Error If Found And Object Changed" }
    ]
  },
  {
    scope: { key: "channelPartner", value: "Channel Partner" },
    rules: [
      { key: "CreateNewIfNotFound", value: "Create New If Not Found" },
      { key: "ErrorIfNotFound", value: "Error If Not Found" },
      { key: "UpdateExistingIfFound", value: "Update Existing If Found" },
      { key: "ErrorIfFoundAndObjectChanged", value: "Error If Found And Object Changed" }
    ]
  },
  {
    scope: { key: "partnerUser", value: "Partner User" },
    rules: [
      { key: "CreateNewIfNotFound", value: "Create New If Not Found" },
      { key: "ErrorIfNotFound", value: "Error If Not Found" },
      { key: "UpdateExistingIfFound", value: "Update Existing If Found" },
      { key: "ErrorIfFoundAndObjectChanged", value: "Error If Found And Object Changed" }
    ]
  }
];

export const ldkConfiguration = [
  {
    scope: { key: "UpdateRules", value: "Update Rules For Continues Orders" },
    rules: [
      { key: "AllowActivationByVendorOnly", value: "Allow Activation By Vendor Only" },
      { key: "SendNotification", value: "Send Notification" },
      { key: "AutoActivation", value: "Auto Activation" }
    ]
  }
];

export const client = {
  GUID: "Add",
  Name: "",
  Email: "",
  ClientCode: "",
  Connector: "",
  ServiceAccountId: "",

  ZuoraConfiguration: {
    client_id: "",
    client_secret: "",
    url: "",
    startDate: "",
    endDate: "",
  },

  SalesforceConfiguration: {
    organizationUrl: "",
  },
  EMSDetails: {
    username: "",
    password: "",
    url: "",
    version: "",
  },


  EMSObjectRules: [
    {
      scope: "Entitlement",
      rules: [],
    },
    {
      scope: "customer",
      rules: ["CreateNewIfNotFound", "UpdateExistingIfFound"],
    },
    {
      scope: "customerContact",
      rules: ["CreateNewIfNotFound", "UpdateExistingIfFound"],
    },
    {
      scope: "channelPartner",
      rules: ["CreateNewIfNotFound", "UpdateExistingIfFound"],
    },
    {
      scope: "partnerUser",
      rules: ["CreateNewIfNotFound", "UpdateExistingIfFound"],
    },
  ],
  EntityLookupParams: [
    {
      scope: "Entitlement",
      entities: [
        {
          name: "entitlement",
          function: "search",
          elements: ["EID"],
          operator: "any",
        },
        {
          name: "customer",
          function: "search",
          elements: ["externalId"],
          operator: "any",
        },
        {
          name: "product",
          function: "search",
          elements: ["externalId", "Skip error, if product not found"],
          operator: "any",
        },
        {
          name: "channelPartner",
          function: "search",
          elements: ["partnerIdentifier"],
          operator: "any",
        },
        {
          name: "customerContact",
          function: "search",
          elements: ["contactEmail"],
          operator: "any",
        },
        {
          name: "sentinelKeyUpdate",
          function: "update",
          elements: ["oldEntitlementId"],
          operator: "any",
        },
      ],
    },
  ],
  LdkConfiguration: [
    {
      scope: "UpdateRules",
      rules: ["AllowActivationByVendorOnly"],
    },
  ],
  QuoteConfiguration: {
    Entitlement: {
      EID: "",
      startDate: "",
      endDate: "",
      refId1: "",
      refId2: "",
      status: "",
      externalId: "",
      entitlementAsWhole: "",
      userRegistration: "",
      sendNotification: "",
      activationAllowed: "",
      revocationAllowed: "",
      ccEmail: "",
      customAttributes: [],
    },
    Product: {
      activationMethod: "",
      availableQuantity: "",
      fixedQuantity: "",
      itemState: "",
      endDate: "",
      totalQuantity: "",
      startDate: "",
      refId1: "",
      externalId: "",
      refId2: "",
      memoryText: "",
      customAttributes: [],
    },
    Customer: {
      customerName: "",
      customerIdentifier: "",
      CRMID: "",
      refId: "",
      externalId: "",
      customAttributes: [],
    },
    CustomerContact: {
      contactName: "",
      contactEmail: "",
      customerName: "",
      externalId: "",
      refId1: "",
      refId2: "",
      loginAllowed: "",
      Password: "",
      billingAddress: {
        address: "",
        city: "",
        country: "",
        state: "",
        postalCode: "",
        useBillingAddressasShippingAddress: "",
      },
      shippingAddress: {
        address: "",
        city: "",
        country: "",
        state: "",
        postalCode: "",
      },
      customAttributes: [],
    },
    ChannelPartner: {
      partnerLogin: {
        externalId: "",
        password: "",
        userIdentifier: "",
        email: "",
        userName: "",
      },
      partnerName: "",
      partnerIdentifier: "",
      externalId: "",
      refId1: "",
      refId2: "",
      status: "",
      billingAddress: {
        address: "",
        city: "",
        country: "",
        state: "",
        postalCode: "",
        useBillingAddressasShippingAddress: "",
      },
      shippingAddress: {
        address: "",
        city: "",
        country: "",
        state: "",
        postalCode: "",
      },
      customAttributes: [],
    },
  },
  OrderConfiguration: {
    Entitlement: {
      EID: "",
      startDate: "",
      endDate: "",
      refId1: "",
      refId2: "",
      externalId: "",
      entitlementAsWhole: "",
      userRegistration: "",
      sendNotification: "",
      activationAllowed: "",
      revocationAllowed: "",
      ccEmail: "",
      status: "",
      customAttributes: [],
    },
    Product: {
      totalQuantity: "",
      startDate: "",
      refId1: "",
      externalId: "",
      refId2: "",
      endDate: "",
      activationMethod: "",
      availableQuantity: "",
      fixedQuantity: "",
      itemState: "",
      memoryText: "",
      customAttributes: [],
    },
    Customer: {
      externalId: "",
      customerName: "",
      customerIdentifier: "",
      CRMID: "",
      refId: "",
      customAttributes: [],
    },
    CustomerContact: {
      contactName: "",
      contactEmail: "",
      customerName: "",
      externalId: "",
      refId1: "",
      refId2: "",
      loginAllowed: "",
      Password: "",
      billingAddress: {
        address: "",
        city: "",
        country: "",
        state: "",
        postalCode: "",
        useBillingAddressasShippingAddress: "",
      },
      shippingAddress: {
        address: "",
        city: "",
        country: "",
        state: "",
        postalCode: "",
      },
      customAttributes: [],
    },
    ChannelPartner: {
      partnerName: "",
      partnerIdentifier: "",
      externalId: "",
      refId1: "",
      refId2: "",
      status: "",
      billingAddress: {
        address: "",
        city: "",
        country: "",
        state: "",
        postalCode: "",
        useBillingAddressasShippingAddress: "",
      },
      shippingAddress: {
        address: "",
        city: "",
        country: "",
        state: "",
        postalCode: "",
      },
      partnerLogin: {
        externalId: "",
        password: "",
        userIdentifier: "",
        email: "",
        userName: "",
      },
      customAttributes: [],
    },
  },
};

export const callOuts = {
  ClientId: "",
  CalloutConfiguration: 
      {
        status: "Enabled",
        header: "Callout",
        trigger: "",
        activity: "",
        allowAuthentication: true,
        authenticationMethod: "Basic",
        userName: "",
        password: "",
        grantType: "client_credentials",
        clientId: "",
        clientSecret: "",
        tokenURL:"",
        method: "",
        payloadType: "JSON",  //predefined the payloadType to "JSON"
        requestPayload: "",
        url: "",
        waitForResponse: true,
        queryParams: [],
        timeOut: null,
        abortIfRequestTimedOut: true,
        calloutExpectedResponseCode: "",
        abortIfCodeDoesntMatch: true,
        replaceKeyValues: [],
        abortIfAnyOfValueNotFound: true,
        validation: false,
        validationUrl: ""
    } 
}
export const ResponseOptions = ['201', '200'];
export const createtriggerOptions = [
  { key: "Before_Activation", value: "Before Activation" },
  { key: "Before_Customer_Handling", value: "Before Customer Handling" },
  { key: "Before_Entitlement_Handling", value: "Before Entitlement Creation" },
  { key: "Before_CustomerContact_Handling", value: "Before Users Handling" }
];


export const activityOptions = [
  {key: "Entitlement_Create" , value: "Entitlement Create" }, 
  {key: "Entitlement_Update" , value: "Entitlement Update" }
];
export const methodOptions = ["GET", "POST"];

export const updatetriggerOptions = [
  { key: "Before_Customer_Handling", value: "Before Customer Handling" },
  { key: "Before_Entitlement_Handling", value: "Before Entitlement Update" },
  { key: "Before_CustomerContact_Handling", value: "Before Users Handling" }
]

export const countryCodes = [
  {
    name: "United States",
    dial_code: "+1",
    code: "US",
    Id: 231,
  },
  {
    name: "Afghanistan",
    dial_code: "+93",
    code: "AF",
    Id: 1,
  },
  {
    name: "Aland Islands",
    dial_code: "+358",
    code: "AX",
    Id: 2,
  },
  {
    name: "Albania",
    dial_code: "+355",
    code: "AL",
    Id: 3,
  },
  {
    name: "Algeria",
    dial_code: "+213",
    code: "DZ",
    Id: 4,
  },
  {
    name: "AmericanSamoa",
    dial_code: "+1 684",
    code: "AS",
    Id: 5,
  },
  {
    name: "Andorra",
    dial_code: "+376",
    code: "AD",
    Id: 6,
  },
  {
    name: "Angola",
    dial_code: "+244",
    code: "AO",
    Id: 7,
  },
  {
    name: "Anguilla",
    dial_code: "+1 264",
    code: "AI",
    Id: 8,
  },
  {
    name: "Antarctica",
    dial_code: "+672",
    code: "AQ",
    Id: 9,
  },
  {
    name: "Antigua and Barbuda",
    dial_code: "+1268",
    code: "AG",
    Id: 10,
  },
  {
    name: "Argentina",
    dial_code: "+54",
    code: "AR",
    Id: 11,
  },
  {
    name: "Armenia",
    dial_code: "+374",
    code: "AM",
    Id: 12,
  },
  {
    name: "Aruba",
    dial_code: "+297",
    code: "AW",
    Id: 13,
  },
  {
    name: "Australia",
    dial_code: "+61",
    code: "AU",
    Id: 14,
  },
  {
    name: "Austria",
    dial_code: "+43",
    code: "AT",
    Id: 15,
  },
  {
    name: "Azerbaijan",
    dial_code: "+994",
    code: "AZ",
    Id: 16,
  },
  {
    name: "Bahamas",
    dial_code: "+1 242",
    code: "BS",
    Id: 17,
  },
  {
    name: "Bahrain",
    dial_code: "+973",
    code: "BH",
    Id: 18,
  },
  {
    name: "Bangladesh",
    dial_code: "+880",
    code: "BD",
    Id: 19,
  },
  {
    name: "Barbados",
    dial_code: "+1 246",
    code: "BB",
    Id: 20,
  },
  {
    name: "Belarus",
    dial_code: "+375",
    code: "BY",
    Id: 21,
  },
  {
    name: "Belgium",
    dial_code: "+32",
    code: "BE",
    Id: 22,
  },
  {
    name: "Belize",
    dial_code: "+501",
    code: "BZ",
    Id: 23,
  },
  {
    name: "Benin",
    dial_code: "+229",
    code: "BJ",
    Id: 24,
  },
  {
    name: "Bermuda",
    dial_code: "+1 441",
    code: "BM",
    Id: 25,
  },
  {
    name: "Bhutan",
    dial_code: "+975",
    code: "BT",
    Id: 26,
  },
  {
    name: "Bolivia, Plurinational State of",
    dial_code: "+591",
    code: "BO",
    Id: 27,
  },
  {
    name: "Bosnia and Herzegovina",
    dial_code: "+387",
    code: "BA",
    Id: 28,
  },
  {
    name: "Botswana",
    dial_code: "+267",
    code: "BW",
    Id: 29,
  },
  {
    name: "Brazil",
    dial_code: "+55",
    code: "BR",
    Id: 30,
  },
  {
    name: "British Indian Ocean Territory",
    dial_code: "+246",
    code: "IO",
    Id: 31,
  },
  {
    name: "Brunei Darussalam",
    dial_code: "+673",
    code: "BN",
    Id: 32,
  },
  {
    name: "Bulgaria",
    dial_code: "+359",
    code: "BG",
    Id: 33,
  },
  {
    name: "Burkina Faso",
    dial_code: "+226",
    code: "BF",
    Id: 34,
  },
  {
    name: "Burundi",
    dial_code: "+257",
    code: "BI",
    Id: 35,
  },
  {
    name: "Cambodia",
    dial_code: "+855",
    code: "KH",
    Id: 36,
  },
  {
    name: "Cameroon",
    dial_code: "+237",
    code: "CM",
    Id: 37,
  },
  {
    name: "Canada",
    dial_code: "+1",
    code: "CA",
    Id: 38,
  },
  {
    name: "Cape Verde",
    dial_code: "+238",
    code: "CV",
    Id: 39,
  },
  {
    name: "Cayman Islands",
    dial_code: "+ 345",
    code: "KY",
    Id: 40,
  },
  {
    name: "Central African Republic",
    dial_code: "+236",
    code: "CF",
    Id: 41,
  },
  {
    name: "Chad",
    dial_code: "+235",
    code: "TD",
    Id: 42,
  },
  {
    name: "Chile",
    dial_code: "+56",
    code: "CL",
    Id: 43,
  },
  {
    name: "China",
    dial_code: "+86",
    code: "CN",
    Id: 44,
  },
  {
    name: "Christmas Island",
    dial_code: "+61",
    code: "CX",
    Id: 45,
  },
  {
    name: "Cocos (Keeling) Islands",
    dial_code: "+61",
    code: "CC",
    Id: 46,
  },
  {
    name: "Colombia",
    dial_code: "+57",
    code: "CO",
    Id: 47,
  },
  {
    name: "Comoros",
    dial_code: "+269",
    code: "KM",
    Id: 48,
  },
  {
    name: "Congo",
    dial_code: "+242",
    code: "CG",
    Id: 49,
  },
  {
    name: "Congo, The Democratic Republic of the",
    dial_code: "+243",
    code: "CD",
    Id: 50,
  },
  {
    name: "Cook Islands",
    dial_code: "+682",
    code: "CK",
    Id: 51,
  },
  {
    name: "Costa Rica",
    dial_code: "+506",
    code: "CR",
    Id: 52,
  },
  {
    name: "Cote d'Ivoire",
    dial_code: "+225",
    code: "CI",
    Id: 53,
  },
  {
    name: "Croatia",
    dial_code: "+385",
    code: "HR",
    Id: 54,
  },
  {
    name: "Cuba",
    dial_code: "+53",
    code: "CU",
    Id: 55,
  },
  {
    name: "Cyprus",
    dial_code: "+537",
    code: "CY",
    Id: 56,
  },
  {
    name: "Czech Republic",
    dial_code: "+420",
    code: "CZ",
    Id: 57,
  },
  {
    name: "Denmark",
    dial_code: "+45",
    code: "DK",
    Id: 58,
  },
  {
    name: "Djibouti",
    dial_code: "+253",
    code: "DJ",
    Id: 59,
  },
  {
    name: "Dominica",
    dial_code: "+1 767",
    code: "DM",
    Id: 60,
  },
  {
    name: "Dominican Republic",
    dial_code: "+1 849",
    code: "DO",
    Id: 61,
  },
  {
    name: "Ecuador",
    dial_code: "+593",
    code: "EC",
    Id: 62,
  },
  {
    name: "Egypt",
    dial_code: "+20",
    code: "EG",
    Id: 63,
  },
  {
    name: "El Salvador",
    dial_code: "+503",
    code: "SV",
    Id: 64,
  },
  {
    name: "Equatorial Guinea",
    dial_code: "+240",
    code: "GQ",
    Id: 65,
  },
  {
    name: "Eritrea",
    dial_code: "+291",
    code: "ER",
    Id: 66,
  },
  {
    name: "Estonia",
    dial_code: "+372",
    code: "EE",
    Id: 67,
  },
  {
    name: "Ethiopia",
    dial_code: "+251",
    code: "ET",
    Id: 68,
  },
  {
    name: "Falkland Islands (Malvinas)",
    dial_code: "+500",
    code: "FK",
    Id: 69,
  },
  {
    name: "Faroe Islands",
    dial_code: "+298",
    code: "FO",
    Id: 70,
  },
  {
    name: "Fiji",
    dial_code: "+679",
    code: "FJ",
    Id: 71,
  },
  {
    name: "Finland",
    dial_code: "+358",
    code: "FI",
    Id: 72,
  },
  {
    name: "France",
    dial_code: "+33",
    code: "FR",
    Id: 73,
  },
  {
    name: "French Guiana",
    dial_code: "+594",
    code: "GF",
    Id: 74,
  },
  {
    name: "French Polynesia",
    dial_code: "+689",
    code: "PF",
    Id: 75,
  },
  {
    name: "Gabon",
    dial_code: "+241",
    code: "GA",
    Id: 76,
  },
  {
    name: "Gambia",
    dial_code: "+220",
    code: "GM",
    Id: 77,
  },
  {
    name: "Georgia",
    dial_code: "+995",
    code: "GE",
    Id: 78,
  },
  {
    name: "Germany",
    dial_code: "+49",
    code: "DE",
    Id: 79,
  },
  {
    name: "Ghana",
    dial_code: "+233",
    code: "GH",
    Id: 80,
  },
  {
    name: "Gibraltar",
    dial_code: "+350",
    code: "GI",
    Id: 81,
  },
  {
    name: "Greece",
    dial_code: "+30",
    code: "GR",
    Id: 82,
  },
  {
    name: "Greenland",
    dial_code: "+299",
    code: "GL",
    Id: 83,
  },
  {
    name: "Grenada",
    dial_code: "+1 473",
    code: "GD",
    Id: 84,
  },
  {
    name: "Guadeloupe",
    dial_code: "+590",
    code: "GP",
    Id: 85,
  },
  {
    name: "Guam",
    dial_code: "+1 671",
    code: "GU",
    Id: 86,
  },
  {
    name: "Guatemala",
    dial_code: "+502",
    code: "GT",
    Id: 87,
  },
  {
    name: "Guernsey",
    dial_code: "+44",
    code: "GG",
    Id: 88,
  },
  {
    name: "Guinea",
    dial_code: "+224",
    code: "GN",
    Id: 89,
  },
  {
    name: "Guinea-Bissau",
    dial_code: "+245",
    code: "GW",
    Id: 90,
  },
  {
    name: "Guyana",
    dial_code: "+595",
    code: "GY",
    Id: 91,
  },
  {
    name: "Haiti",
    dial_code: "+509",
    code: "HT",
    Id: 92,
  },
  {
    name: "Holy See (Vatican City State)",
    dial_code: "+379",
    code: "VA",
    Id: 93,
  },
  {
    name: "Honduras",
    dial_code: "+504",
    code: "HN",
    Id: 94,
  },
  {
    name: "Hong Kong",
    dial_code: "+852",
    code: "HK",
    Id: 95,
  },
  {
    name: "Hungary",
    dial_code: "+36",
    code: "HU",
    Id: 96,
  },
  {
    name: "Iceland",
    dial_code: "+354",
    code: "IS",
    Id: 97,
  },
  {
    name: "India",
    dial_code: "+91",
    code: "IN",
    Id: 98,
  },
  {
    name: "Indonesia",
    dial_code: "+62",
    code: "ID",
    Id: 99,
  },
  {
    name: "Iran, Islamic Republic of",
    dial_code: "+98",
    code: "IR",
    Id: 100,
  },
  {
    name: "Iraq",
    dial_code: "+964",
    code: "IQ",
    Id: 101,
  },
  {
    name: "Ireland",
    dial_code: "+353",
    code: "IE",
    Id: 102,
  },
  {
    name: "Isle of Man",
    dial_code: "+44",
    code: "IM",
    Id: 103,
  },
  {
    name: "Israel",
    dial_code: "+972",
    code: "IL",
    Id: 104,
  },
  {
    name: "Israel",
    dial_code: "+972",
    code: "IL",
    Id: 105,
  },
  {
    name: "Italy",
    dial_code: "+39",
    code: "IT",
    Id: 106,
  },
  {
    name: "Jamaica",
    dial_code: "+1 876",
    code: "JM",
    Id: 107,
  },
  {
    name: "Japan",
    dial_code: "+81",
    code: "JP",
    Id: 108,
  },
  {
    name: "Jersey",
    dial_code: "+44",
    code: "JE",
    Id: 109,
  },
  {
    name: "Jordan",
    dial_code: "+962",
    code: "JO",
    Id: 110,
  },
  {
    name: "Kazakhstan",
    dial_code: "+7 7",
    code: "KZ",
    Id: 111,
  },
  {
    name: "Kenya",
    dial_code: "+254",
    code: "KE",
    Id: 112,
  },
  {
    name: "Kiribati",
    dial_code: "+686",
    code: "KI",
    Id: 113,
  },
  {
    name: "Korea, Democratic People's Republic of",
    dial_code: "+850",
    code: "KP",
    Id: 114,
  },
  {
    name: "Korea, Republic of",
    dial_code: "+82",
    code: "KR",
    Id: 115,
  },
  {
    name: "Kuwait",
    dial_code: "+965",
    code: "KW",
    Id: 116,
  },
  {
    name: "Kyrgyzstan",
    dial_code: "+996",
    code: "KG",
    Id: 117,
  },
  {
    name: "Lao People's Democratic Republic",
    dial_code: "+856",
    code: "LA",
    Id: 118,
  },
  {
    name: "Latvia",
    dial_code: "+371",
    code: "LV",
    Id: 119,
  },
  {
    name: "Lebanon",
    dial_code: "+961",
    code: "LB",
    Id: 120,
  },
  {
    name: "Lesotho",
    dial_code: "+266",
    code: "LS",
    Id: 121,
  },
  {
    name: "Liberia",
    dial_code: "+231",
    code: "LR",
    Id: 122,
  },
  {
    name: "Libyan Arab Jamahiriya",
    dial_code: "+218",
    code: "LY",
    Id: 123,
  },
  {
    name: "Liechtenstein",
    dial_code: "+423",
    code: "LI",
    Id: 124,
  },
  {
    name: "Lithuania",
    dial_code: "+370",
    code: "LT",
    Id: 125,
  },
  {
    name: "Luxembourg",
    dial_code: "+352",
    code: "LU",
    Id: 126,
  },
  {
    name: "Macao",
    dial_code: "+853",
    code: "MO",
    Id: 127,
  },
  {
    name: "Macedonia, The Former Yugoslav Republic of",
    dial_code: "+389",
    code: "MK",
    Id: 128,
  },
  {
    name: "Madagascar",
    dial_code: "+261",
    code: "MG",
    Id: 129,
  },
  {
    name: "Malawi",
    dial_code: "+265",
    code: "MW",
    Id: 130,
  },
  {
    name: "Malaysia",
    dial_code: "+60",
    code: "MY",
    Id: 131,
  },
  {
    name: "Maldives",
    dial_code: "+960",
    code: "MV",
    Id: 132,
  },
  {
    name: "Mali",
    dial_code: "+223",
    code: "ML",
    Id: 133,
  },
  {
    name: "Malta",
    dial_code: "+356",
    code: "MT",
    Id: 134,
  },
  {
    name: "Marshall Islands",
    dial_code: "+692",
    code: "MH",
    Id: 135,
  },
  {
    name: "Martinique",
    dial_code: "+596",
    code: "MQ",
    Id: 136,
  },
  {
    name: "Mauritania",
    dial_code: "+222",
    code: "MR",
    Id: 137,
  },
  {
    name: "Mauritius",
    dial_code: "+230",
    code: "MU",
    Id: 138,
  },
  {
    name: "Mayotte",
    dial_code: "+262",
    code: "YT",
    Id: 139,
  },
  {
    name: "Mexico",
    dial_code: "+52",
    code: "MX",
    Id: 140,
  },
  {
    name: "Micronesia, Federated States of",
    dial_code: "+691",
    code: "FM",
    Id: 141,
  },
  {
    name: "Moldova, Republic of",
    dial_code: "+373",
    code: "MD",
    Id: 142,
  },
  {
    name: "Monaco",
    dial_code: "+377",
    code: "MC",
    Id: 143,
  },
  {
    name: "Mongolia",
    dial_code: "+976",
    code: "MN",
    Id: 144,
  },
  {
    name: "Montenegro",
    dial_code: "+382",
    code: "ME",
    Id: 145,
  },
  {
    name: "Montserrat",
    dial_code: "+1664",
    code: "MS",
    Id: 146,
  },
  {
    name: "Morocco",
    dial_code: "+212",
    code: "MA",
    Id: 147,
  },
  {
    name: "Mozambique",
    dial_code: "+258",
    code: "MZ",
    Id: 148,
  },
  {
    name: "Myanmar",
    dial_code: "+95",
    code: "MM",
    Id: 149,
  },
  {
    name: "Namibia",
    dial_code: "+264",
    code: "NA",
    Id: 150,
  },
  {
    name: "Nauru",
    dial_code: "+674",
    code: "NR",
    Id: 151,
  },
  {
    name: "Nepal",
    dial_code: "+977",
    code: "NP",
    Id: 152,
  },
  {
    name: "Netherlands",
    dial_code: "+31",
    code: "NL",
    Id: 153,
  },
  {
    name: "Netherlands Antilles",
    dial_code: "+599",
    code: "AN",
    Id: 154,
  },
  {
    name: "New Caledonia",
    dial_code: "+687",
    code: "NC",
    Id: 155,
  },
  {
    name: "New Zealand",
    dial_code: "+64",
    code: "NZ",
    Id: 156,
  },
  {
    name: "Nicaragua",
    dial_code: "+505",
    code: "NI",
    Id: 157,
  },
  {
    name: "Niger",
    dial_code: "+227",
    code: "NE",
    Id: 158,
  },
  {
    name: "Nigeria",
    dial_code: "+234",
    code: "NG",
    Id: 159,
  },
  {
    name: "Niue",
    dial_code: "+683",
    code: "NU",
    Id: 160,
  },
  {
    name: "Norfolk Island",
    dial_code: "+672",
    code: "NF",
    Id: 161,
  },
  {
    name: "Northern Mariana Islands",
    dial_code: "+1 670",
    code: "MP",
    Id: 162,
  },
  {
    name: "Norway",
    dial_code: "+47",
    code: "NO",
    Id: 163,
  },
  {
    name: "Oman",
    dial_code: "+968",
    code: "OM",
    Id: 164,
  },
  {
    name: "Pakistan",
    dial_code: "+92",
    code: "PK",
    Id: 165,
  },
  {
    name: "Palau",
    dial_code: "+680",
    code: "PW",
    Id: 166,
  },
  {
    name: "Palestinian Territory, Occupied",
    dial_code: "+970",
    code: "PS",
    Id: 167,
  },
  {
    name: "Panama",
    dial_code: "+507",
    code: "PA",
    Id: 168,
  },
  {
    name: "Papua New Guinea",
    dial_code: "+675",
    code: "PG",
    Id: 169,
  },
  {
    name: "Paraguay",
    dial_code: "+595",
    code: "PY",
    Id: 170,
  },
  {
    name: "Peru",
    dial_code: "+51",
    code: "PE",
    Id: 171,
  },
  {
    name: "Philippines",
    dial_code: "+63",
    code: "PH",
    Id: 172,
  },
  {
    name: "Pitcairn",
    dial_code: "+872",
    code: "PN",
    Id: 173,
  },
  {
    name: "Poland",
    dial_code: "+48",
    code: "PL",
    Id: 174,
  },
  {
    name: "Portugal",
    dial_code: "+351",
    code: "PT",
    Id: 175,
  },
  {
    name: "Puerto Rico",
    dial_code: "+1 939",
    code: "PR",
    Id: 176,
  },
  {
    name: "Qatar",
    dial_code: "+974",
    code: "QA",
    Id: 177,
  },
  {
    name: "Romania",
    dial_code: "+40",
    code: "RO",
    Id: 178,
  },
  {
    name: "Russia",
    dial_code: "+7",
    code: "RU",
    Id: 179,
  },
  {
    name: "Rwanda",
    dial_code: "+250",
    code: "RW",
    Id: 180,
  },
  {
    name: "Réunion",
    dial_code: "+262",
    code: "RE",
    Id: 181,
  },
  {
    name: "Saint Barthélemy",
    dial_code: "+590",
    code: "BL",
    Id: 182,
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dial_code: "+290",
    code: "SH",
    Id: 183,
  },
  {
    name: "Saint Kitts and Nevis",
    dial_code: "+1 869",
    code: "KN",
    Id: 184,
  },
  {
    name: "Saint Lucia",
    dial_code: "+1 758",
    code: "LC",
    Id: 185,
  },
  {
    name: "Saint Martin",
    dial_code: "+590",
    code: "MF",
    Id: 186,
  },
  {
    name: "Saint Pierre and Miquelon",
    dial_code: "+508",
    code: "PM",
    Id: 187,
  },
  {
    name: "Saint Vincent and the Grenadines",
    dial_code: "+1 784",
    code: "VC",
    Id: 188,
  },
  {
    name: "Samoa",
    dial_code: "+685",
    code: "WS",
    Id: 189,
  },
  {
    name: "San Marino",
    dial_code: "+378",
    code: "SM",
    Id: 190,
  },
  {
    name: "Sao Tome and Principe",
    dial_code: "+239",
    code: "ST",
    Id: 191,
  },
  {
    name: "Saudi Arabia",
    dial_code: "+966",
    code: "SA",
    Id: 192,
  },
  {
    name: "Senegal",
    dial_code: "+221",
    code: "SN",
    Id: 193,
  },
  {
    name: "Serbia",
    dial_code: "+381",
    code: "RS",
    Id: 194,
  },
  {
    name: "Seychelles",
    dial_code: "+248",
    code: "SC",
    Id: 195,
  },
  {
    name: "Sierra Leone",
    dial_code: "+232",
    code: "SL",
    Id: 196,
  },
  {
    name: "Singapore",
    dial_code: "+65",
    code: "SG",
    Id: 197,
  },
  {
    name: "Slovakia",
    dial_code: "+421",
    code: "SK",
    Id: 198,
  },
  {
    name: "Slovenia",
    dial_code: "+386",
    code: "SI",
    Id: 199,
  },
  {
    name: "Solomon Islands",
    dial_code: "+677",
    code: "SB",
    Id: 200,
  },
  {
    name: "Somalia",
    dial_code: "+252",
    code: "SO",
    Id: 201,
  },
  {
    name: "South Africa",
    dial_code: "+27",
    code: "ZA",
    Id: 202,
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    dial_code: "+500",
    code: "GS",
    Id: 203,
  },
  {
    name: "Spain",
    dial_code: "+34",
    code: "ES",
    Id: 204,
  },
  {
    name: "Sri Lanka",
    dial_code: "+94",
    code: "LK",
    Id: 205,
  },
  {
    name: "Sudan",
    dial_code: "+249",
    code: "SD",
    Id: 206,
  },
  {
    name: "Suriname",
    dial_code: "+597",
    code: "SR",
    Id: 207,
  },
  {
    name: "Svalbard and Jan Mayen",
    dial_code: "+47",
    code: "SJ",
    Id: 208,
  },
  {
    name: "Swaziland",
    dial_code: "+268",
    code: "SZ",
    Id: 209,
  },
  {
    name: "Sweden",
    dial_code: "+46",
    code: "SE",
    Id: 210,
  },
  {
    name: "Switzerland",
    dial_code: "+41",
    code: "CH",
    Id: 211,
  },
  {
    name: "Syrian Arab Republic",
    dial_code: "+963",
    code: "SY",
    Id: 212,
  },
  {
    name: "Taiwan, Province of China",
    dial_code: "+886",
    code: "TW",
    Id: 213,
  },
  {
    name: "Tajikistan",
    dial_code: "+992",
    code: "TJ",
    Id: 214,
  },
  {
    name: "Tanzania, United Republic of",
    dial_code: "+255",
    code: "TZ",
    Id: 215,
  },
  {
    name: "Thailand",
    dial_code: "+66",
    code: "TH",
    Id: 216,
  },
  {
    name: "Timor-Leste",
    dial_code: "+670",
    code: "TL",
    Id: 217,
  },
  {
    name: "Togo",
    dial_code: "+228",
    code: "TG",
    Id: 218,
  },
  {
    name: "Tokelau",
    dial_code: "+690",
    code: "TK",
    Id: 219,
  },
  {
    name: "Tonga",
    dial_code: "+676",
    code: "TO",
    Id: 220,
  },
  {
    name: "Trinidad and Tobago",
    dial_code: "+1 868",
    code: "TT",
    Id: 221,
  },
  {
    name: "Tunisia",
    dial_code: "+216",
    code: "TN",
    Id: 222,
  },
  {
    name: "Turkey",
    dial_code: "+90",
    code: "TR",
    Id: 223,
  },
  {
    name: "Turkmenistan",
    dial_code: "+993",
    code: "TM",
    Id: 224,
  },
  {
    name: "Turks and Caicos Islands",
    dial_code: "+1 649",
    code: "TC",
    Id: 225,
  },
  {
    name: "Tuvalu",
    dial_code: "+688",
    code: "TV",
    Id: 226,
  },
  {
    name: "Uganda",
    dial_code: "+256",
    code: "UG",
    Id: 227,
  },
  {
    name: "Ukraine",
    dial_code: "+380",
    code: "UA",
    Id: 228,
  },
  {
    name: "United Arab Emirates",
    dial_code: "+971",
    code: "AE",
    Id: 229,
  },
  {
    name: "United Kingdom",
    dial_code: "+44",
    code: "GB",
    Id: 230,
  },
  {
    name: "Uruguay",
    dial_code: "+598",
    code: "UY",
    Id: 232,
  },
  {
    name: "Uzbekistan",
    dial_code: "+998",
    code: "UZ",
    Id: 233,
  },
  {
    name: "Vanuatu",
    dial_code: "+678",
    code: "VU",
    Id: 234,
  },
  {
    name: "Venezuela, Bolivarian Republic of",
    dial_code: "+58",
    code: "VE",
    Id: 235,
  },
  {
    name: "Viet Nam",
    dial_code: "+84",
    code: "VN",
    Id: 236,
  },
  {
    name: "Virgin Islands, British",
    dial_code: "+1 284",
    code: "VG",
    Id: 237,
  },
  {
    name: "Virgin Islands, U.S.",
    dial_code: "+1 340",
    code: "VI",
    Id: 238,
  },
  {
    name: "Wallis and Futuna",
    dial_code: "+681",
    code: "WF",
    Id: 239,
  },
  {
    name: "Yemen",
    dial_code: "+967",
    code: "YE",
    Id: 240,
  },
  {
    name: "Zambia",
    dial_code: "+260",
    code: "ZM",
    Id: 241,
  },
  {
    name: "Zimbabwe",
    dial_code: "+263",
    code: "ZW",
    Id: 242,
  },
];
